<template>
  <main class="faq">
    <div class="faq_wrap">
      <h1>
        무엇을 도와드릴까요?<br />
        Blue Button에 궁금한 점을 문의해 주세요.
      </h1>
      <div class="faq_container">
        <ul class="faq_list">
          <li v-for="(item, index) in items" :key="index">
            <p @click="toggle(index)">Q. {{ item.question }}</p>
            <div class="answer">
              <div>A.</div>
              <div>
                {{ item.answer }}
              </div>
            </div>
            <div class="toggle">
              <img
                src="@/assets/icons/pdetail-down-arrow.png"
                alt="open"
                @click="toggle(index)"
                class="answer_icon_down"
              />
              <img
                src="@/assets/icons/pdetail-up-arrow.png"
                alt="close"
                @click="toggle(index)"
                class="answer_icon_up"
              />
            </div>
          </li>
        </ul>
      </div>
      <div class="question">
        <div class="call_faq">
          <h3>친절한 전화문의</h3>
          <p>
            FAQ에서 찾을 수 없는 궁금한 사항은 <br />
            Blue Button의 친절한 전화문의로 연락 주세요.
          </p>
          <p>
            문의전화 : 02-6337-0310 <br />
            운영시간 : 09.30 ~ 18.30 (점심시간 : 12.00 ~ 13.00)
          </p>
        </div>
        <div class="persnal_faq">
          <h3>빠르고 친절한 1:1문의</h3>
          <p>
            FAQ에 궁금한 사항이 없다면 1:1 문의를 통해 질문해 주세요. <br />
            빠르고 친절한 답변을 드립니다.
          </p>
          <button @click="openQnaPopup">문의하기</button>
        </div>
      </div>
    </div>
    <Policy v-if="openPolicy" @close="openPolicy = false" />
    <Terms v-if="openTerms" @close="openTerms = false" />
  </main>
</template>

<script>
import QnaPopup from "@/components/QnaPopup.vue";
import Policy from "@/components/Policy.vue";
import Terms from "@/components/Terms";
import { mapGetters } from "vuex";

export default {
  components: {
    Policy,
    Terms,
  },
  data() {
    return {
      openPolicy: false,
      openTerms: false,
      items: [
        {
          question: "회원가입은 어떻게 하나요?",
          answer:
            "아이디는 프로젝트 등록 및 미팅과 연계되어 있어 변경이 불가능 합니다. 만약, 꼭 아이디를 변경해야 한다면 회원 탈퇴 후 새 계정으로 가입하셔야 합니다.",
        },
        {
          question: "회원가입은 어떻게 하나요?11",
          answer:
            "아이디는 프로젝트 등록 및 미팅과 연계되어 있어 변경이 불가능 합니다. 만약, 꼭 아이디를 변경해야 한다면 회원 탈퇴 후 새 계정으로 가입하셔야 합니다.11",
        },
        {
          question: "회원가입은 어떻게 하나요?22",
          answer:
            "아이디는 프로젝트 등록 및 미팅과 연계되어 있어 변경이 불가능 합니다. 만약, 꼭 아이디를 변경해야 한다면 회원 탈퇴 후 새 계정으로 가입하셔야 합니다.22",
        },
        {
          question: "회원가입은 어떻게 하나요?33",
          answer:
            "아이디는 프로젝트 등록 및 미팅과 연계되어 있어 변경이 불가능 합니다. 만약, 꼭 아이디를 변경해야 한다면 회원 탈퇴 후 새 계정으로 가입하셔야 합니다.33",
        },
      ],
    };
  },
  methods: {
    openQnaPopup() {
      if (!this.isAuthenticated) {
        if (confirm("로그인이 필요합니다. 로그인을 하시겠습니까?")) {
          this.$router.push({
            name: "Login",
          });
        }
        return;
      }

      this.$modal.show(
        QnaPopup,
        {},
        { width: 720, height: "auto" },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    toggle(idx) {
      document.querySelectorAll(".answer").forEach(function (e, i) {
        if (idx == i) {
          e.classList.toggle("active");
        }
      });
      document.querySelectorAll(".answer_icon_down").forEach(function (e, i) {
        if (idx == i) {
          e.classList.toggle("active");
          document
            .querySelectorAll(".answer_icon_up")
            .forEach(function (el, idx) {
              if (i == idx) {
                el.classList.toggle("active");
              }
            });
        }
      });
    },
  },
  watch: {
    openQnaPopup: function () {
      if (this.openQnaPopup) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
    },
    openQnaDetailPopup: function () {
      if (this.openQnaDetailPopup) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      if (!this.openQnaPopup && !this.openQnaDetailPopup) {
        document.documentElement.style.overflow = "auto";
      }
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>
